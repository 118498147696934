import React from 'react';
import { Sidebar, SidebarContent, SidebarFooter, SidebarGroup, SidebarGroupContent, SidebarGroupLabel, SidebarHeader, SidebarMenu, SidebarMenuButton, SidebarMenuItem, SidebarMenuSub, SidebarMenuSubButton, SidebarMenuSubItem, } from '@/components/ui/sidebar';
import { ChartBarBig, ChevronRight, ChevronUp, CircleDollarSign, FileBox, FilePlus, LayoutDashboard, LogOut, MonitorSmartphone, PackageSearch, Settings, Shapes, Table, TicketPercent, User2, Users, } from 'lucide-react';
import { Link, useLocation } from '@tanstack/react-router';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger, } from '@/components/ui/dropdown-menu';
import { useAuthentication } from '@/hooks/use-authentication';
import i18next from '@/i18next';
import { useTranslation } from 'react-i18next';
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '@radix-ui/react-collapsible';
const items = [
    {
        title: i18next.t('dashboard'),
        url: '/console',
        icon: LayoutDashboard,
    },
    {
        title: i18next.t('orders'),
        icon: FileBox,
        children: [
            {
                title: i18next.t('new.title'),
                url: '/console/orders/news',
                icon: FilePlus,
            },
            {
                title: i18next.t('table'),
                url: '/console/orders',
                icon: Table,
            },
            {
                title: i18next.t('charts'),
                url: '/console/orders/charts',
                icon: ChartBarBig,
            },
        ],
    },
    {
        title: i18next.t('payments'),
        icon: CircleDollarSign,
        children: [
            {
                title: i18next.t('table'),
                url: '/console/payments',
                icon: Table,
            },
            {
                title: i18next.t('charts'),
                url: '/console/payments/charts',
                icon: ChartBarBig,
            },
        ],
    },
    {
        title: i18next.t('products'),
        url: '/console/products',
        icon: PackageSearch,
    },
    {
        title: i18next.t('coupons'),
        icon: TicketPercent,
        children: [
            {
                title: i18next.t('new.title'),
                url: '/console/coupons/new',
                icon: FilePlus,
            },
            {
                title: i18next.t('table'),
                url: '/console/coupons',
                icon: Table,
            },
        ],
    },
    {
        title: i18next.t('categories'),
        url: '/console/categories',
        icon: Shapes,
    },
    {
        title: i18next.t('devices'),
        url: '/console/devices',
        icon: MonitorSmartphone,
    },
    {
        title: i18next.t('users'),
        url: '/console/users',
        icon: Users,
    },
    {
        title: i18next.t('settings'),
        url: '/console/settings',
        icon: Settings,
    },
];
export const AppSidebar = () => {
    const { user } = useAuthentication();
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const renderMenuSub = React.useCallback((items) => {
        return items.map((item) => {
            const Icon = item.icon;
            return (<SidebarMenuSubItem key={item.title}>
                        <SidebarMenuSubButton asChild isActive={pathname === item.url}>
                            <Link to={item.url}>
                                <Icon />
                                <span>{item.title}</span>
                            </Link>
                        </SidebarMenuSubButton>
                    </SidebarMenuSubItem>);
        });
    }, [pathname]);
    const renderMenu = React.useCallback((items) => items.map((item) => {
        const Icon = item.icon;
        if (item.children && item.children.length > 0) {
            return (<Collapsible defaultOpen className="group/collapsible" key={item.title}>
                            <CollapsibleTrigger asChild>
                                <SidebarMenuButton>
                                    <Icon />
                                    <span>{item.title}</span>
                                    <ChevronRight className="ml-auto transition-transform group-data-[state=open]/collapsible:rotate-90"/>
                                </SidebarMenuButton>
                            </CollapsibleTrigger>
                            <CollapsibleContent>
                                <SidebarMenuSub>{renderMenuSub(item.children)}</SidebarMenuSub>
                            </CollapsibleContent>
                        </Collapsible>);
        }
        return (<SidebarMenuItem key={item.title}>
                        <SidebarMenuButton asChild isActive={pathname === item.url}>
                            <Link to={item.url}>
                                <Icon />
                                <span>{item.title}</span>
                            </Link>
                        </SidebarMenuButton>
                    </SidebarMenuItem>);
    }), [pathname, renderMenuSub]);
    return (<Sidebar>
            <SidebarHeader>
                <h1 className="text-2xl font-bold">Beef & Basil</h1>
                <p className="text-sm text-gray-500">{t('admin_console')}</p>
            </SidebarHeader>
            <SidebarContent>
                <SidebarGroup>
                    <SidebarGroupLabel>{t('application')}</SidebarGroupLabel>
                    <SidebarGroupContent>
                        <SidebarMenu>{renderMenu(items)}</SidebarMenu>
                    </SidebarGroupContent>
                </SidebarGroup>
            </SidebarContent>
            <SidebarFooter>
                <SidebarMenu>
                    <SidebarMenuItem>
                        <DropdownMenu>
                            <DropdownMenuTrigger asChild>
                                <SidebarMenuButton>
                                    <User2 /> {user?.username}
                                    <ChevronUp className="ml-auto"/>
                                </SidebarMenuButton>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent side="top" className="w-[--radix-popper-anchor-width]">
                                <DropdownMenuItem>
                                    <a href="/console/logout" className="text-red-500 w-full flex flex-row space-x-2 items-center">
                                        <LogOut className="text-red-500"/>
                                        <span>{t('logout')}</span>
                                    </a>
                                </DropdownMenuItem>
                            </DropdownMenuContent>
                        </DropdownMenu>
                    </SidebarMenuItem>
                </SidebarMenu>
            </SidebarFooter>
        </Sidebar>);
};
