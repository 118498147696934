import React from 'react';
import { useTranslation } from 'react-i18next';
import { badgeVariants } from '@/components/ui/badge';
export const PeriodBadge = ({ value, withAll, dispatch }) => {
    const { t } = useTranslation();
    const periods = React.useMemo(() => {
        const options = [
            {
                label: t('day'),
                value: 'D',
            },
            {
                label: t('week'),
                value: 'W',
            },
            {
                label: t('month'),
                value: 'M',
            },
            {
                label: t('year'),
                value: 'Y',
            },
        ];
        if (withAll) {
            options.push({
                label: t('all'),
                value: 'A',
            });
        }
        return options;
    }, [t, withAll]);
    return periods.map((period, index) => (<button key={`period-option-item-${index}`} className={badgeVariants({ variant: value === period.value ? 'default' : 'outline' })} onClick={() => dispatch({ type: `SET_PERIOD_${period.value}`, payload: period.value })}>
            {period.label}
        </button>));
};
