import { endOfDay, endOfMonth, endOfWeek, endOfYear, startOfDay, startOfMonth, startOfWeek, startOfYear, } from 'date-fns';
export const initialState = {
    period: 'A',
    from: undefined,
    to: undefined,
};
export const periodReducer = (state, action) => {
    const today = new Date();
    switch (action.type) {
        case 'SET_PERIOD_A':
            return { ...state, period: action.payload, from: undefined, to: undefined };
        case 'SET_PERIOD_D':
            return { ...state, period: action.payload, from: startOfDay(today), to: endOfDay(today) };
        case 'SET_PERIOD_W':
            return {
                ...state,
                period: action.payload,
                from: startOfWeek(today),
                to: endOfWeek(today),
            };
        case 'SET_PERIOD_M':
            return {
                ...state,
                period: action.payload,
                from: startOfMonth(today),
                to: endOfMonth(today),
            };
        case 'SET_PERIOD_Y':
            return {
                ...state,
                period: action.payload,
                from: startOfYear(today),
                to: endOfYear(today),
            };
        case 'SET_PERIOD':
            return { ...state, ...action.payload };
        case 'RESET':
            return initialState;
        default:
            return state;
    }
};
