import { apiAxios } from '@/lib/apiAxios';
import { formatISO } from 'date-fns';
const getFormattedData = (data) => {
    return {
        ...data,
        items: data.items.map((item) => ({
            ...item,
            product: item.product.id,
            coupon: item.coupon?.id,
        })),
    };
};
export const fetchOrders = async (searchOrder) => apiAxios
    .get('/orders', searchOrder && {
    params: {
        ...searchOrder,
        state: undefined,
        from: searchOrder.from?.toISOString(),
        to: searchOrder.to?.toISOString(),
        states: [searchOrder.state],
    },
})
    .then((response) => response.data);
export const fetchOrderAmounts = (period) => apiAxios
    .get(`/orders/amounts`, { params: { ...period, from: period?.from && formatISO(period.from), to: period?.to && formatISO(period.to) } })
    .then((response) => response.data);
export const fetchOrder = async (referenceNumber) => apiAxios.get(`/orders/${referenceNumber}`).then((response) => response.data);
export const createOrder = async (order) => apiAxios
    .post('/orders', getFormattedData(order))
    .then((response) => response.data);
export const updateOrder = async ({ referenceNumber, data }) => {
    console.log(getFormattedData(data));
    return apiAxios
        .put(`/orders/${referenceNumber}`, getFormattedData(data))
        .then((response) => response.data);
};
export const payOrder = async ({ referenceNumber, data }) => apiAxios
    .patch(`/orders/${referenceNumber}/transitions/pay`, { ...data, paymentMethod: data.paymentMethod })
    .then((response) => response.data);
export const applyCoupon = async ({ referenceNumber, couponCode }) => apiAxios
    .patch(`/orders/${referenceNumber}/coupons/${couponCode}`)
    .then((response) => response.data);
export const removeCoupon = async ({ referenceNumber, couponCode }) => apiAxios
    .delete(`/orders/${referenceNumber}/coupons/${couponCode}`)
    .then((response) => response.data);
export const cancelOrder = async (referenceNumber) => apiAxios
    .patch(`/orders/${referenceNumber}/transitions/cancel`)
    .then((response) => response.data);
