import React from 'react';
import { createFileRoute } from '@tanstack/react-router';
import { CouponTable } from '@/components/coupon-table';
import { useQuery } from '@tanstack/react-query';
import { fetchCoupons } from '@/services/coupon';
export const Route = createFileRoute('/console/_authenticated/_layout/coupons/')({
    component: Coupons,
});
function Coupons() {
    const { data = [] } = useQuery({
        queryKey: ['coupons'],
        queryFn: () => fetchCoupons(),
    });
    return (<div>
            <CouponTable coupons={data}/>
        </div>);
}
