import { apiAxios } from '@/lib/apiAxios';
export const fetchPayments = async (params) => apiAxios
    .get('/payments', {
    params: {
        ...params,
        from: params.from?.toISOString(),
        to: params.to?.toISOString(),
        paymentMethod: params.paymentMethod === '0' ? undefined : params.paymentMethod,
    },
})
    .then((response) => response.data);
export const fetchPayment = async (referenceNumber) => apiAxios.get(`/payments/${referenceNumber}`).then((response) => response.data);
