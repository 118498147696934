import React from 'react';
import { Button } from '@/components/ui/button';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { addDays, addMonths, addWeeks, addYears, format, subDays, subMonths, subWeeks, subYears } from 'date-fns';
import { DATE_FORMAT } from '@/constants/date';
import { useTranslation } from 'react-i18next';
export const ChartPeriodButtons = React.memo(({ state, dispatch, total }) => {
    const { t } = useTranslation();
    const handlePreviousPeriod = React.useCallback(() => {
        if (state.from && state.to) {
            switch (state.period) {
                case 'D': {
                    dispatch({
                        type: 'SET_PERIOD',
                        payload: { ...state, from: subDays(state.from, 1), to: subDays(state.to, 1) },
                    });
                    break;
                }
                case 'W': {
                    dispatch({
                        type: 'SET_PERIOD',
                        payload: {
                            ...state,
                            from: subWeeks(state.from, 1),
                            to: subWeeks(state.to, 1),
                        },
                    });
                    break;
                }
                case 'M': {
                    dispatch({
                        type: 'SET_PERIOD',
                        payload: {
                            ...state,
                            from: subMonths(state.from, 1),
                            to: subMonths(state.to, 1),
                        },
                    });
                    break;
                }
                case 'Y': {
                    dispatch({
                        type: 'SET_PERIOD',
                        payload: {
                            ...state,
                            from: subYears(state.from, 1),
                            to: subYears(state.to, 1),
                        },
                    });
                    break;
                }
            }
        }
    }, [dispatch, state]);
    const handleNextPeriod = React.useCallback(() => {
        if (state.from && state.to) {
            switch (state.period) {
                case 'D': {
                    dispatch({
                        type: 'SET_PERIOD',
                        payload: { ...state, from: addDays(state.from, 1), to: addDays(state.to, 1) },
                    });
                    break;
                }
                case 'W': {
                    dispatch({
                        type: 'SET_PERIOD',
                        payload: {
                            ...state,
                            from: addWeeks(state.from, 1),
                            to: addWeeks(state.to, 1),
                        },
                    });
                    break;
                }
                case 'M': {
                    dispatch({
                        type: 'SET_PERIOD',
                        payload: {
                            ...state,
                            from: addMonths(state.from, 1),
                            to: addMonths(state.to, 1),
                        },
                    });
                    break;
                }
                case 'Y': {
                    dispatch({
                        type: 'SET_PERIOD',
                        payload: {
                            ...state,
                            from: addYears(state.from, 1),
                            to: addYears(state.to, 1),
                        },
                    });
                    break;
                }
            }
        }
    }, [dispatch, state]);
    if (state.from && state.to) {
        return (<div className="flex flex-col space-y-2 justify-between items-center w-full mt-2">
                <div className="flex justify-between items-center w-full">
                    <Button onClick={handlePreviousPeriod}>
                        <ChevronLeft />
                        {t('previous')}
                    </Button>
                    <p>
                        {format(state.from, DATE_FORMAT)} - {format(state.to, DATE_FORMAT)}
                    </p>
                    <Button onClick={handleNextPeriod}>
                        {t('next')}
                        <ChevronRight />
                    </Button>
                </div>
                {total && (<div className="flex items-center space-x-2 justify-center">
                        <p className="text-2xl font-bold fill-foreground">{total.toLocaleString()}</p>
                        <p className="text-lg fill-muted-foreground">{t('product.chart.total', { ns: 'glossary' })}</p>
                    </div>)}
            </div>);
    }
    return null;
});
