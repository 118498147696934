import { apiAxios } from '@/lib/apiAxios';
import { formatISO } from 'date-fns';
export const fetchCategories = () => apiAxios.get('/categories').then((res) => res.data);
export const fetchCategoryShares = (period = {}) => apiAxios
    .get('/categories/shares', { params: { ...period, from: period?.from && formatISO(period.from), to: period?.to && formatISO(period.to) } })
    .then((res) => res.data);
export const fetchCategory = (canonicalName) => apiAxios.get(`/categories/${canonicalName}`).then((res) => res.data);
export const createCategory = (data) => apiAxios.post('/categories', data).then((res) => res.data);
export const updateCategory = ({ canonicalName, data }) => apiAxios.put(`/categories/${canonicalName}`, data).then((res) => res.data);
export const deleteCategory = (canonicalName) => apiAxios.delete(`/categories/${canonicalName}`).then((res) => res.data);
