import { apiAxios } from '@/lib/apiAxios';
import { formatISO } from 'date-fns';
export const fetchProducts = (params = {}) => apiAxios.get('/products', { params }).then((response) => response.data);
export const fetchProductShares = (period) => apiAxios
    .get('/products/shares', { params: { ...period, from: period?.from && formatISO(period.from), to: period?.to && formatISO(period.to) } })
    .then((response) => response.data);
export const fetchProduct = (identifier) => apiAxios.get(`/products/${identifier}`).then((response) => response.data);
export const createProduct = async ({ data, imageFile }) => {
    const formData = new FormData();
    formData.append('name', data.name);
    if (data.description) {
        formData.append('description', data.description);
    }
    formData.append('price', String(data.price));
    formData.append('category', String(data.category));
    formData.append('imageFile', imageFile, imageFile.name);
    return apiAxios
        .post('/products', formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })
        .then((response) => response.data);
};
export const updateProduct = ({ identifier, data }) => apiAxios
    .put(`/products/${identifier}`, data)
    .then((response) => response.data);
export const deleteProduct = (identifier) => apiAxios.delete(`/products/${identifier}`).then((response) => response.data);
export const uploadProductImage = async ({ identifier, imageFile }) => {
    const formData = new FormData();
    formData.append('imageFile', imageFile, imageFile.name);
    return apiAxios
        .post(`/products/${identifier}/uploads`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })
        .then((response) => response.data);
};
export const patchProduct = ({ identifier }) => apiAxios.patch(`/products/${identifier}`).then((response) => response.data);
