import React from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteProduct } from '@/services/product';
import { toast } from 'sonner';
import { isAxiosError } from 'axios';
import { Button } from '@/components/ui/button';
import { ArrowDown, ArrowUp, Pencil, Trash2 } from 'lucide-react';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { currencyFormat } from '@/lib/string';
import { ProductAvailableSwitch } from '@/components/product-available-switch';
import { useReactTable } from '@tanstack/react-table';
import { createColumnHelper, getCoreRowModel, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, } from '@tanstack/table-core';
import { Table } from '@/components/table';
import { useTranslation } from 'react-i18next';
import { Link } from '@tanstack/react-router';
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle, AlertDialogTrigger, } from '@/components/ui/alert-dialog';
const columnHelper = createColumnHelper();
export const ProductTable = ({ products }) => {
    const { t } = useTranslation();
    const [sorting, setSorting] = React.useState([]);
    const [columnFilters, setColumnFilters] = React.useState([]);
    const [columnVisibility, setColumnVisibility] = React.useState({});
    const [rowSelection, setRowSelection] = React.useState({});
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
        mutationFn: deleteProduct,
        onSuccess: (data) => {
            toast.success(data.message);
            queryClient.invalidateQueries({ queryKey: ['products'] }).then();
        },
        onError: (error) => {
            const message = isAxiosError(error)
                ? error.response?.data.message
                : t('error_occurred', { ns: 'glossary' });
            toast.error(message);
        },
    });
    const columns = React.useMemo(() => [
        columnHelper.accessor('name', {
            header: ({ column }) => (<Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}>
                        {t('name')}
                        {column.getIsSorted() === 'asc' ? <ArrowUp /> : <ArrowDown />}
                    </Button>),
            cell: (info) => info.renderValue(),
            footer: (info) => info.column.id,
        }),
        columnHelper.accessor('canonicalName', {
            header: ({ column }) => (<Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}>
                        {t('canonical_name')}
                        {column.getIsSorted() === 'asc' ? <ArrowUp /> : <ArrowDown />}
                    </Button>),
            cell: (info) => info.renderValue(),
            footer: (info) => info.column.id,
        }),
        columnHelper.accessor('imageUrl', {
            header: () => t('image'),
            cell: (info) => (<Avatar>
                        <AvatarImage src={info.getValue()} alt={info.row.original.canonicalName}/>
                        <AvatarFallback>{info.row.original.canonicalName}</AvatarFallback>
                    </Avatar>),
            footer: (info) => info.column.id,
            enableSorting: false,
            enableColumnFilter: false,
            meta: {
                style: { textAlign: 'center' },
            },
        }),
        columnHelper.accessor('price', {
            header: ({ column }) => (<Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}>
                        {t('price')}
                        {column.getIsSorted() === 'asc' ? <ArrowUp /> : <ArrowDown />}
                    </Button>),
            cell: (info) => currencyFormat(Number(info.renderValue())),
            footer: (info) => info.column.id,
            meta: {
                style: { textAlign: 'right' },
            },
        }),
        columnHelper.accessor('category.name', {
            header: ({ column }) => (<Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}>
                        {t('category')}
                        {column.getIsSorted() === 'asc' ? <ArrowUp /> : <ArrowDown />}
                    </Button>),
            cell: (info) => info.renderValue(),
            footer: (info) => info.column.id,
        }),
        columnHelper.accessor('available', {
            header: () => t('available'),
            cell: (info) => <ProductAvailableSwitch product={info.row.original}/>,
            footer: (info) => info.column.id,
            meta: {
                style: { textAlign: 'center' },
            },
        }),
        columnHelper.accessor('identifier', {
            id: 'actions',
            enableHiding: false,
            header: () => t('actions'),
            cell: ({ getValue }) => (<React.Fragment>
                        <Button variant="ghost" size="icon" asChild>
                            <Link to={`/console/products/${getValue()}`}>
                                <Pencil />
                            </Link>
                        </Button>
                        <AlertDialog key={`device-web-token-${getValue()}-button-delete`}>
                            <AlertDialogTrigger asChild>
                                <Button variant="ghost" size="icon" className="text-red-500">
                                    <Trash2 />
                                    {t('delete')}
                                </Button>
                            </AlertDialogTrigger>
                            <AlertDialogContent>
                                <AlertDialogHeader>
                                    <AlertDialogTitle>{t('delete.product.title', { ns: 'glossary' })}</AlertDialogTitle>
                                    <AlertDialogDescription>
                                        {t('delete.product.description', { ns: 'glossary' })}
                                    </AlertDialogDescription>
                                </AlertDialogHeader>
                                <AlertDialogFooter>
                                    <AlertDialogCancel>{t('close')}</AlertDialogCancel>
                                    <AlertDialogAction onClick={() => {
                    mutate(getValue());
                }}>
                                        {t('continue')}
                                    </AlertDialogAction>
                                </AlertDialogFooter>
                            </AlertDialogContent>
                        </AlertDialog>
                    </React.Fragment>),
            meta: {
                style: {
                    textAlign: 'center',
                },
            },
        }),
    ], [mutate, t]);
    const table = useReactTable({
        data: products,
        columns,
        onSortingChange: setSorting,
        onColumnFiltersChange: setColumnFilters,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        onColumnVisibilityChange: setColumnVisibility,
        onRowSelectionChange: setRowSelection,
        state: {
            sorting,
            columnFilters,
            columnVisibility,
            rowSelection,
        },
    });
    return <Table table={table} columns={columns}/>;
};
