import { apiAxios } from '@/lib/apiAxios';
import { formatISO } from 'date-fns';
export const fetchCoupons = async () => apiAxios.get('/coupons').then((res) => res.data);
export const createCoupon = async (coupon) => apiAxios
    .post('/coupons', {
    ...coupon,
    endDate: coupon.endDate && formatISO(coupon.endDate),
    startDate: coupon.startDate && formatISO(coupon.startDate),
})
    .then((res) => res.data);
export const fetchCoupon = async (code) => apiAxios.get(`/coupons/${code}`).then((res) => res.data);
export const checkCoupon = async (code) => apiAxios.get(`/coupons/${code}/check`).then((res) => res.data);
