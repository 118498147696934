import React from 'react';
import { createFileRoute, Link } from '@tanstack/react-router';
import { Button } from '@/components/ui/button';
import { FilePlus, Search } from 'lucide-react';
import { OrderState } from '@/types/Order';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { TextField } from '@/components/text-field';
import { SelectField } from '@/components/select-field';
import { OrderTable } from '@/components/order-table';
import { useQuery } from '@tanstack/react-query';
import { fetchOrders } from '@/services/order';
import { Form } from '@/components/ui/form';
import { DatePicker } from '@/components/date-picker';
export const Route = createFileRoute('/console/_authenticated/_layout/orders/')({
    component: Orders,
});
function Orders() {
    const { t } = useTranslation();
    const form = useForm({
        defaultValues: {
            invoiceNumber: '',
            state: OrderState.OPEN,
            from: null,
            to: null,
        },
    });
    const { data = [], refetch } = useQuery({
        queryKey: ['orders'],
        queryFn: () => fetchOrders({ ...form.getValues(), state: form.getValues().state }),
    });
    const onSearch = () => {
        refetch().then();
    };
    const options = React.useMemo(() => Object.values(OrderState).map((state) => ({
        label: t(`common:${state}`),
        value: state,
    })), [t]);
    return (<div className="w-full">
            <div className="py-4 border rounded-lg p-2 mb-2">
                <Form {...form}>
                    <div className="flex flex-col p-2 gap-2">
                        <TextField control={form.control} name="invoiceNumber" label={t('invoice_number')} placeholder={t('placeholder.order.invoice_number', { ns: 'glossary' })}/>
                        <div className="flex flex-row justify-center space-x-2 items-end w-full">
                            <DatePicker control={form.control} name="from" label={t('from')} disabledDate={(date) => date > new Date() || date < new Date('1900-01-01')}/>
                            <DatePicker control={form.control} name="to" label={t('to')} disabledDate={(date) => date > new Date() || date < new Date('1900-01-01')}/>
                            <SelectField control={form.control} name="state" options={options} label={t('state')}/>
                        </div>
                    </div>
                </Form>
                <div className="flex flex-row justify-center items-center space-x-2">
                    <Button variant="outline" className="bg-yellow-500 text-background" onClick={onSearch}>
                        <Search />
                        {t('search')}
                    </Button>
                    <Button asChild>
                        <Link to="/console/orders/news" className="flex flex-row items-center space-x-2">
                            <FilePlus />
                            {t('new.order')}
                        </Link>
                    </Button>
                </div>
            </div>
            <OrderTable orders={data}/>
        </div>);
}
