/* eslint-disable */
// @ts-nocheck
// noinspection JSUnusedGlobalSymbols
// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.
import { createFileRoute } from '@tanstack/react-router';
// Import Routes
import { Route as rootRoute } from './routes/__root';
import { Route as IndexImport } from './routes/index';
import { Route as ConsoleLoginImport } from './routes/console/login';
import { Route as ConsoleAuthenticatedImport } from './routes/console/_authenticated';
import { Route as ConsoleAuthenticatedLayoutImport } from './routes/console/_authenticated/_layout';
import { Route as ConsoleAuthenticatedLayoutIndexImport } from './routes/console/_authenticated/_layout/index';
import { Route as ConsoleAuthenticatedLayoutUsersIndexImport } from './routes/console/_authenticated/_layout/users/index';
import { Route as ConsoleAuthenticatedLayoutProductsIndexImport } from './routes/console/_authenticated/_layout/products/index';
import { Route as ConsoleAuthenticatedLayoutPaymentsIndexImport } from './routes/console/_authenticated/_layout/payments/index';
import { Route as ConsoleAuthenticatedLayoutOrdersIndexImport } from './routes/console/_authenticated/_layout/orders/index';
import { Route as ConsoleAuthenticatedLayoutDevicesIndexImport } from './routes/console/_authenticated/_layout/devices/index';
import { Route as ConsoleAuthenticatedLayoutCouponsIndexImport } from './routes/console/_authenticated/_layout/coupons/index';
import { Route as ConsoleAuthenticatedLayoutCategoriesIndexImport } from './routes/console/_authenticated/_layout/categories/index';
import { Route as ConsoleAuthenticatedLayoutSettingsLayoutImport } from './routes/console/_authenticated/_layout/settings/_layout';
import { Route as ConsoleAuthenticatedLayoutSettingsLayoutIndexImport } from './routes/console/_authenticated/_layout/settings/_layout/index';
import { Route as ConsoleAuthenticatedLayoutUsersUsernameLayoutImport } from './routes/console/_authenticated/_layout/users/$username/_layout';
// Create Virtual Routes
const ConsoleImport = createFileRoute('/console')();
const ConsoleAuthenticatedLayoutSettingsImport = createFileRoute('/console/_authenticated/_layout/settings')();
const ConsoleAuthenticatedLayoutUsersUsernameImport = createFileRoute('/console/_authenticated/_layout/users/$username')();
const ConsoleAuthenticatedLayoutUsersNewLazyImport = createFileRoute('/console/_authenticated/_layout/users/new')();
const ConsoleAuthenticatedLayoutProductsNewLazyImport = createFileRoute('/console/_authenticated/_layout/products/new')();
const ConsoleAuthenticatedLayoutProductsIdentifierLazyImport = createFileRoute('/console/_authenticated/_layout/products/$identifier')();
const ConsoleAuthenticatedLayoutPaymentsChartsLazyImport = createFileRoute('/console/_authenticated/_layout/payments/charts')();
const ConsoleAuthenticatedLayoutPaymentsReferenceNumberLazyImport = createFileRoute('/console/_authenticated/_layout/payments/$referenceNumber')();
const ConsoleAuthenticatedLayoutOrdersNewsLazyImport = createFileRoute('/console/_authenticated/_layout/orders/news')();
const ConsoleAuthenticatedLayoutOrdersChartsLazyImport = createFileRoute('/console/_authenticated/_layout/orders/charts')();
const ConsoleAuthenticatedLayoutOrdersReferenceNumberLazyImport = createFileRoute('/console/_authenticated/_layout/orders/$referenceNumber')();
const ConsoleAuthenticatedLayoutDevicesWebTokenLazyImport = createFileRoute('/console/_authenticated/_layout/devices/$webToken')();
const ConsoleAuthenticatedLayoutCouponsNewLazyImport = createFileRoute('/console/_authenticated/_layout/coupons/new')();
const ConsoleAuthenticatedLayoutCouponsCodeLazyImport = createFileRoute('/console/_authenticated/_layout/coupons/$code')();
const ConsoleAuthenticatedLayoutCategoriesCanonicalNameLazyImport = createFileRoute('/console/_authenticated/_layout/categories/$canonicalName')();
const ConsoleAuthenticatedLayoutSettingsLayoutStoreLazyImport = createFileRoute('/console/_authenticated/_layout/settings/_layout/store')();
const ConsoleAuthenticatedLayoutUsersUsernameLayoutIndexLazyImport = createFileRoute('/console/_authenticated/_layout/users/$username/_layout/')();
const ConsoleAuthenticatedLayoutUsersUsernameLayoutChangePasswordLazyImport = createFileRoute('/console/_authenticated/_layout/users/$username/_layout/change-password')();
const ConsoleAuthenticatedLayoutUsersUsernameLayoutAccessKeysLazyImport = createFileRoute('/console/_authenticated/_layout/users/$username/_layout/access-keys')();
// Create/Update Routes
const ConsoleRoute = ConsoleImport.update({
    id: '/console',
    path: '/console',
    getParentRoute: () => rootRoute,
});
const IndexRoute = IndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => rootRoute,
});
const ConsoleLoginRoute = ConsoleLoginImport.update({
    id: '/login',
    path: '/login',
    getParentRoute: () => ConsoleRoute,
});
const ConsoleAuthenticatedRoute = ConsoleAuthenticatedImport.update({
    id: '/_authenticated',
    getParentRoute: () => ConsoleRoute,
});
const ConsoleAuthenticatedLayoutRoute = ConsoleAuthenticatedLayoutImport.update({
    id: '/_layout',
    getParentRoute: () => ConsoleAuthenticatedRoute,
});
const ConsoleAuthenticatedLayoutSettingsRoute = ConsoleAuthenticatedLayoutSettingsImport.update({
    id: '/settings',
    path: '/settings',
    getParentRoute: () => ConsoleAuthenticatedLayoutRoute,
});
const ConsoleAuthenticatedLayoutIndexRoute = ConsoleAuthenticatedLayoutIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => ConsoleAuthenticatedLayoutRoute,
});
const ConsoleAuthenticatedLayoutUsersUsernameRoute = ConsoleAuthenticatedLayoutUsersUsernameImport.update({
    id: '/users/$username',
    path: '/users/$username',
    getParentRoute: () => ConsoleAuthenticatedLayoutRoute,
});
const ConsoleAuthenticatedLayoutUsersIndexRoute = ConsoleAuthenticatedLayoutUsersIndexImport.update({
    id: '/users/',
    path: '/users/',
    getParentRoute: () => ConsoleAuthenticatedLayoutRoute,
});
const ConsoleAuthenticatedLayoutProductsIndexRoute = ConsoleAuthenticatedLayoutProductsIndexImport.update({
    id: '/products/',
    path: '/products/',
    getParentRoute: () => ConsoleAuthenticatedLayoutRoute,
});
const ConsoleAuthenticatedLayoutPaymentsIndexRoute = ConsoleAuthenticatedLayoutPaymentsIndexImport.update({
    id: '/payments/',
    path: '/payments/',
    getParentRoute: () => ConsoleAuthenticatedLayoutRoute,
});
const ConsoleAuthenticatedLayoutOrdersIndexRoute = ConsoleAuthenticatedLayoutOrdersIndexImport.update({
    id: '/orders/',
    path: '/orders/',
    getParentRoute: () => ConsoleAuthenticatedLayoutRoute,
});
const ConsoleAuthenticatedLayoutDevicesIndexRoute = ConsoleAuthenticatedLayoutDevicesIndexImport.update({
    id: '/devices/',
    path: '/devices/',
    getParentRoute: () => ConsoleAuthenticatedLayoutRoute,
});
const ConsoleAuthenticatedLayoutCouponsIndexRoute = ConsoleAuthenticatedLayoutCouponsIndexImport.update({
    id: '/coupons/',
    path: '/coupons/',
    getParentRoute: () => ConsoleAuthenticatedLayoutRoute,
});
const ConsoleAuthenticatedLayoutCategoriesIndexRoute = ConsoleAuthenticatedLayoutCategoriesIndexImport.update({
    id: '/categories/',
    path: '/categories/',
    getParentRoute: () => ConsoleAuthenticatedLayoutRoute,
});
const ConsoleAuthenticatedLayoutUsersNewLazyRoute = ConsoleAuthenticatedLayoutUsersNewLazyImport.update({
    id: '/users/new',
    path: '/users/new',
    getParentRoute: () => ConsoleAuthenticatedLayoutRoute,
}).lazy(() => import('./routes/console/_authenticated/_layout/users/new.lazy').then((d) => d.Route));
const ConsoleAuthenticatedLayoutProductsNewLazyRoute = ConsoleAuthenticatedLayoutProductsNewLazyImport.update({
    id: '/products/new',
    path: '/products/new',
    getParentRoute: () => ConsoleAuthenticatedLayoutRoute,
}).lazy(() => import('./routes/console/_authenticated/_layout/products/new.lazy').then((d) => d.Route));
const ConsoleAuthenticatedLayoutProductsIdentifierLazyRoute = ConsoleAuthenticatedLayoutProductsIdentifierLazyImport.update({
    id: '/products/$identifier',
    path: '/products/$identifier',
    getParentRoute: () => ConsoleAuthenticatedLayoutRoute,
}).lazy(() => import('./routes/console/_authenticated/_layout/products/$identifier.lazy').then((d) => d.Route));
const ConsoleAuthenticatedLayoutPaymentsChartsLazyRoute = ConsoleAuthenticatedLayoutPaymentsChartsLazyImport.update({
    id: '/payments/charts',
    path: '/payments/charts',
    getParentRoute: () => ConsoleAuthenticatedLayoutRoute,
}).lazy(() => import('./routes/console/_authenticated/_layout/payments/charts.lazy').then((d) => d.Route));
const ConsoleAuthenticatedLayoutPaymentsReferenceNumberLazyRoute = ConsoleAuthenticatedLayoutPaymentsReferenceNumberLazyImport.update({
    id: '/payments/$referenceNumber',
    path: '/payments/$referenceNumber',
    getParentRoute: () => ConsoleAuthenticatedLayoutRoute,
}).lazy(() => import('./routes/console/_authenticated/_layout/payments/$referenceNumber.lazy').then((d) => d.Route));
const ConsoleAuthenticatedLayoutOrdersNewsLazyRoute = ConsoleAuthenticatedLayoutOrdersNewsLazyImport.update({
    id: '/orders/news',
    path: '/orders/news',
    getParentRoute: () => ConsoleAuthenticatedLayoutRoute,
}).lazy(() => import('./routes/console/_authenticated/_layout/orders/news.lazy').then((d) => d.Route));
const ConsoleAuthenticatedLayoutOrdersChartsLazyRoute = ConsoleAuthenticatedLayoutOrdersChartsLazyImport.update({
    id: '/orders/charts',
    path: '/orders/charts',
    getParentRoute: () => ConsoleAuthenticatedLayoutRoute,
}).lazy(() => import('./routes/console/_authenticated/_layout/orders/charts.lazy').then((d) => d.Route));
const ConsoleAuthenticatedLayoutOrdersReferenceNumberLazyRoute = ConsoleAuthenticatedLayoutOrdersReferenceNumberLazyImport.update({
    id: '/orders/$referenceNumber',
    path: '/orders/$referenceNumber',
    getParentRoute: () => ConsoleAuthenticatedLayoutRoute,
}).lazy(() => import('./routes/console/_authenticated/_layout/orders/$referenceNumber.lazy').then((d) => d.Route));
const ConsoleAuthenticatedLayoutDevicesWebTokenLazyRoute = ConsoleAuthenticatedLayoutDevicesWebTokenLazyImport.update({
    id: '/devices/$webToken',
    path: '/devices/$webToken',
    getParentRoute: () => ConsoleAuthenticatedLayoutRoute,
}).lazy(() => import('./routes/console/_authenticated/_layout/devices/$webToken.lazy').then((d) => d.Route));
const ConsoleAuthenticatedLayoutCouponsNewLazyRoute = ConsoleAuthenticatedLayoutCouponsNewLazyImport.update({
    id: '/coupons/new',
    path: '/coupons/new',
    getParentRoute: () => ConsoleAuthenticatedLayoutRoute,
}).lazy(() => import('./routes/console/_authenticated/_layout/coupons/new.lazy').then((d) => d.Route));
const ConsoleAuthenticatedLayoutCouponsCodeLazyRoute = ConsoleAuthenticatedLayoutCouponsCodeLazyImport.update({
    id: '/coupons/$code',
    path: '/coupons/$code',
    getParentRoute: () => ConsoleAuthenticatedLayoutRoute,
}).lazy(() => import('./routes/console/_authenticated/_layout/coupons/$code.lazy').then((d) => d.Route));
const ConsoleAuthenticatedLayoutCategoriesCanonicalNameLazyRoute = ConsoleAuthenticatedLayoutCategoriesCanonicalNameLazyImport.update({
    id: '/categories/$canonicalName',
    path: '/categories/$canonicalName',
    getParentRoute: () => ConsoleAuthenticatedLayoutRoute,
}).lazy(() => import('./routes/console/_authenticated/_layout/categories/$canonicalName.lazy').then((d) => d.Route));
const ConsoleAuthenticatedLayoutSettingsLayoutRoute = ConsoleAuthenticatedLayoutSettingsLayoutImport.update({
    id: '/_layout',
    getParentRoute: () => ConsoleAuthenticatedLayoutSettingsRoute,
});
const ConsoleAuthenticatedLayoutSettingsLayoutIndexRoute = ConsoleAuthenticatedLayoutSettingsLayoutIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => ConsoleAuthenticatedLayoutSettingsLayoutRoute,
});
const ConsoleAuthenticatedLayoutSettingsLayoutStoreLazyRoute = ConsoleAuthenticatedLayoutSettingsLayoutStoreLazyImport.update({
    id: '/store',
    path: '/store',
    getParentRoute: () => ConsoleAuthenticatedLayoutSettingsLayoutRoute,
}).lazy(() => import('./routes/console/_authenticated/_layout/settings/_layout/store.lazy').then((d) => d.Route));
const ConsoleAuthenticatedLayoutUsersUsernameLayoutRoute = ConsoleAuthenticatedLayoutUsersUsernameLayoutImport.update({
    id: '/_layout',
    getParentRoute: () => ConsoleAuthenticatedLayoutUsersUsernameRoute,
});
const ConsoleAuthenticatedLayoutUsersUsernameLayoutIndexLazyRoute = ConsoleAuthenticatedLayoutUsersUsernameLayoutIndexLazyImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => ConsoleAuthenticatedLayoutUsersUsernameLayoutRoute,
}).lazy(() => import('./routes/console/_authenticated/_layout/users/$username/_layout/index.lazy').then((d) => d.Route));
const ConsoleAuthenticatedLayoutUsersUsernameLayoutChangePasswordLazyRoute = ConsoleAuthenticatedLayoutUsersUsernameLayoutChangePasswordLazyImport.update({
    id: '/change-password',
    path: '/change-password',
    getParentRoute: () => ConsoleAuthenticatedLayoutUsersUsernameLayoutRoute,
}).lazy(() => import('./routes/console/_authenticated/_layout/users/$username/_layout/change-password.lazy').then((d) => d.Route));
const ConsoleAuthenticatedLayoutUsersUsernameLayoutAccessKeysLazyRoute = ConsoleAuthenticatedLayoutUsersUsernameLayoutAccessKeysLazyImport.update({
    id: '/access-keys',
    path: '/access-keys',
    getParentRoute: () => ConsoleAuthenticatedLayoutUsersUsernameLayoutRoute,
}).lazy(() => import('./routes/console/_authenticated/_layout/users/$username/_layout/access-keys.lazy').then((d) => d.Route));
const ConsoleAuthenticatedLayoutSettingsLayoutRouteChildren = {
    ConsoleAuthenticatedLayoutSettingsLayoutStoreLazyRoute: ConsoleAuthenticatedLayoutSettingsLayoutStoreLazyRoute,
    ConsoleAuthenticatedLayoutSettingsLayoutIndexRoute: ConsoleAuthenticatedLayoutSettingsLayoutIndexRoute,
};
const ConsoleAuthenticatedLayoutSettingsLayoutRouteWithChildren = ConsoleAuthenticatedLayoutSettingsLayoutRoute._addFileChildren(ConsoleAuthenticatedLayoutSettingsLayoutRouteChildren);
const ConsoleAuthenticatedLayoutSettingsRouteChildren = {
    ConsoleAuthenticatedLayoutSettingsLayoutRoute: ConsoleAuthenticatedLayoutSettingsLayoutRouteWithChildren,
};
const ConsoleAuthenticatedLayoutSettingsRouteWithChildren = ConsoleAuthenticatedLayoutSettingsRoute._addFileChildren(ConsoleAuthenticatedLayoutSettingsRouteChildren);
const ConsoleAuthenticatedLayoutUsersUsernameLayoutRouteChildren = {
    ConsoleAuthenticatedLayoutUsersUsernameLayoutAccessKeysLazyRoute: ConsoleAuthenticatedLayoutUsersUsernameLayoutAccessKeysLazyRoute,
    ConsoleAuthenticatedLayoutUsersUsernameLayoutChangePasswordLazyRoute: ConsoleAuthenticatedLayoutUsersUsernameLayoutChangePasswordLazyRoute,
    ConsoleAuthenticatedLayoutUsersUsernameLayoutIndexLazyRoute: ConsoleAuthenticatedLayoutUsersUsernameLayoutIndexLazyRoute,
};
const ConsoleAuthenticatedLayoutUsersUsernameLayoutRouteWithChildren = ConsoleAuthenticatedLayoutUsersUsernameLayoutRoute._addFileChildren(ConsoleAuthenticatedLayoutUsersUsernameLayoutRouteChildren);
const ConsoleAuthenticatedLayoutUsersUsernameRouteChildren = {
    ConsoleAuthenticatedLayoutUsersUsernameLayoutRoute: ConsoleAuthenticatedLayoutUsersUsernameLayoutRouteWithChildren,
};
const ConsoleAuthenticatedLayoutUsersUsernameRouteWithChildren = ConsoleAuthenticatedLayoutUsersUsernameRoute._addFileChildren(ConsoleAuthenticatedLayoutUsersUsernameRouteChildren);
const ConsoleAuthenticatedLayoutRouteChildren = {
    ConsoleAuthenticatedLayoutIndexRoute: ConsoleAuthenticatedLayoutIndexRoute,
    ConsoleAuthenticatedLayoutSettingsRoute: ConsoleAuthenticatedLayoutSettingsRouteWithChildren,
    ConsoleAuthenticatedLayoutCategoriesCanonicalNameLazyRoute: ConsoleAuthenticatedLayoutCategoriesCanonicalNameLazyRoute,
    ConsoleAuthenticatedLayoutCouponsCodeLazyRoute: ConsoleAuthenticatedLayoutCouponsCodeLazyRoute,
    ConsoleAuthenticatedLayoutCouponsNewLazyRoute: ConsoleAuthenticatedLayoutCouponsNewLazyRoute,
    ConsoleAuthenticatedLayoutDevicesWebTokenLazyRoute: ConsoleAuthenticatedLayoutDevicesWebTokenLazyRoute,
    ConsoleAuthenticatedLayoutOrdersReferenceNumberLazyRoute: ConsoleAuthenticatedLayoutOrdersReferenceNumberLazyRoute,
    ConsoleAuthenticatedLayoutOrdersChartsLazyRoute: ConsoleAuthenticatedLayoutOrdersChartsLazyRoute,
    ConsoleAuthenticatedLayoutOrdersNewsLazyRoute: ConsoleAuthenticatedLayoutOrdersNewsLazyRoute,
    ConsoleAuthenticatedLayoutPaymentsReferenceNumberLazyRoute: ConsoleAuthenticatedLayoutPaymentsReferenceNumberLazyRoute,
    ConsoleAuthenticatedLayoutPaymentsChartsLazyRoute: ConsoleAuthenticatedLayoutPaymentsChartsLazyRoute,
    ConsoleAuthenticatedLayoutProductsIdentifierLazyRoute: ConsoleAuthenticatedLayoutProductsIdentifierLazyRoute,
    ConsoleAuthenticatedLayoutProductsNewLazyRoute: ConsoleAuthenticatedLayoutProductsNewLazyRoute,
    ConsoleAuthenticatedLayoutUsersNewLazyRoute: ConsoleAuthenticatedLayoutUsersNewLazyRoute,
    ConsoleAuthenticatedLayoutCategoriesIndexRoute: ConsoleAuthenticatedLayoutCategoriesIndexRoute,
    ConsoleAuthenticatedLayoutCouponsIndexRoute: ConsoleAuthenticatedLayoutCouponsIndexRoute,
    ConsoleAuthenticatedLayoutDevicesIndexRoute: ConsoleAuthenticatedLayoutDevicesIndexRoute,
    ConsoleAuthenticatedLayoutOrdersIndexRoute: ConsoleAuthenticatedLayoutOrdersIndexRoute,
    ConsoleAuthenticatedLayoutPaymentsIndexRoute: ConsoleAuthenticatedLayoutPaymentsIndexRoute,
    ConsoleAuthenticatedLayoutProductsIndexRoute: ConsoleAuthenticatedLayoutProductsIndexRoute,
    ConsoleAuthenticatedLayoutUsersIndexRoute: ConsoleAuthenticatedLayoutUsersIndexRoute,
    ConsoleAuthenticatedLayoutUsersUsernameRoute: ConsoleAuthenticatedLayoutUsersUsernameRouteWithChildren,
};
const ConsoleAuthenticatedLayoutRouteWithChildren = ConsoleAuthenticatedLayoutRoute._addFileChildren(ConsoleAuthenticatedLayoutRouteChildren);
const ConsoleAuthenticatedRouteChildren = {
    ConsoleAuthenticatedLayoutRoute: ConsoleAuthenticatedLayoutRouteWithChildren,
};
const ConsoleAuthenticatedRouteWithChildren = ConsoleAuthenticatedRoute._addFileChildren(ConsoleAuthenticatedRouteChildren);
const ConsoleRouteChildren = {
    ConsoleAuthenticatedRoute: ConsoleAuthenticatedRouteWithChildren,
    ConsoleLoginRoute: ConsoleLoginRoute,
};
const ConsoleRouteWithChildren = ConsoleRoute._addFileChildren(ConsoleRouteChildren);
const rootRouteChildren = {
    IndexRoute: IndexRoute,
    ConsoleRoute: ConsoleRouteWithChildren,
};
export const routeTree = rootRoute
    ._addFileChildren(rootRouteChildren)
    ._addFileTypes();
/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/console"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/console": {
      "filePath": "console",
      "children": [
        "/console/_authenticated",
        "/console/login"
      ]
    },
    "/console/_authenticated": {
      "filePath": "console/_authenticated.ts",
      "parent": "/console",
      "children": [
        "/console/_authenticated/_layout"
      ]
    },
    "/console/login": {
      "filePath": "console/login.tsx",
      "parent": "/console"
    },
    "/console/_authenticated/_layout": {
      "filePath": "console/_authenticated/_layout.tsx",
      "parent": "/console/_authenticated",
      "children": [
        "/console/_authenticated/_layout/",
        "/console/_authenticated/_layout/settings",
        "/console/_authenticated/_layout/categories/$canonicalName",
        "/console/_authenticated/_layout/coupons/$code",
        "/console/_authenticated/_layout/coupons/new",
        "/console/_authenticated/_layout/devices/$webToken",
        "/console/_authenticated/_layout/orders/$referenceNumber",
        "/console/_authenticated/_layout/orders/charts",
        "/console/_authenticated/_layout/orders/news",
        "/console/_authenticated/_layout/payments/$referenceNumber",
        "/console/_authenticated/_layout/payments/charts",
        "/console/_authenticated/_layout/products/$identifier",
        "/console/_authenticated/_layout/products/new",
        "/console/_authenticated/_layout/users/new",
        "/console/_authenticated/_layout/categories/",
        "/console/_authenticated/_layout/coupons/",
        "/console/_authenticated/_layout/devices/",
        "/console/_authenticated/_layout/orders/",
        "/console/_authenticated/_layout/payments/",
        "/console/_authenticated/_layout/products/",
        "/console/_authenticated/_layout/users/",
        "/console/_authenticated/_layout/users/$username"
      ]
    },
    "/console/_authenticated/_layout/": {
      "filePath": "console/_authenticated/_layout/index.tsx",
      "parent": "/console/_authenticated/_layout"
    },
    "/console/_authenticated/_layout/settings": {
      "filePath": "console/_authenticated/_layout/settings",
      "parent": "/console/_authenticated/_layout",
      "children": [
        "/console/_authenticated/_layout/settings/_layout"
      ]
    },
    "/console/_authenticated/_layout/settings/_layout": {
      "filePath": "console/_authenticated/_layout/settings/_layout.tsx",
      "parent": "/console/_authenticated/_layout/settings",
      "children": [
        "/console/_authenticated/_layout/settings/_layout/store",
        "/console/_authenticated/_layout/settings/_layout/"
      ]
    },
    "/console/_authenticated/_layout/categories/$canonicalName": {
      "filePath": "console/_authenticated/_layout/categories/$canonicalName.lazy.tsx",
      "parent": "/console/_authenticated/_layout"
    },
    "/console/_authenticated/_layout/coupons/$code": {
      "filePath": "console/_authenticated/_layout/coupons/$code.lazy.tsx",
      "parent": "/console/_authenticated/_layout"
    },
    "/console/_authenticated/_layout/coupons/new": {
      "filePath": "console/_authenticated/_layout/coupons/new.lazy.tsx",
      "parent": "/console/_authenticated/_layout"
    },
    "/console/_authenticated/_layout/devices/$webToken": {
      "filePath": "console/_authenticated/_layout/devices/$webToken.lazy.tsx",
      "parent": "/console/_authenticated/_layout"
    },
    "/console/_authenticated/_layout/orders/$referenceNumber": {
      "filePath": "console/_authenticated/_layout/orders/$referenceNumber.lazy.tsx",
      "parent": "/console/_authenticated/_layout"
    },
    "/console/_authenticated/_layout/orders/charts": {
      "filePath": "console/_authenticated/_layout/orders/charts.lazy.tsx",
      "parent": "/console/_authenticated/_layout"
    },
    "/console/_authenticated/_layout/orders/news": {
      "filePath": "console/_authenticated/_layout/orders/news.lazy.tsx",
      "parent": "/console/_authenticated/_layout"
    },
    "/console/_authenticated/_layout/payments/$referenceNumber": {
      "filePath": "console/_authenticated/_layout/payments/$referenceNumber.lazy.tsx",
      "parent": "/console/_authenticated/_layout"
    },
    "/console/_authenticated/_layout/payments/charts": {
      "filePath": "console/_authenticated/_layout/payments/charts.lazy.tsx",
      "parent": "/console/_authenticated/_layout"
    },
    "/console/_authenticated/_layout/products/$identifier": {
      "filePath": "console/_authenticated/_layout/products/$identifier.lazy.tsx",
      "parent": "/console/_authenticated/_layout"
    },
    "/console/_authenticated/_layout/products/new": {
      "filePath": "console/_authenticated/_layout/products/new.lazy.tsx",
      "parent": "/console/_authenticated/_layout"
    },
    "/console/_authenticated/_layout/users/new": {
      "filePath": "console/_authenticated/_layout/users/new.lazy.tsx",
      "parent": "/console/_authenticated/_layout"
    },
    "/console/_authenticated/_layout/categories/": {
      "filePath": "console/_authenticated/_layout/categories/index.tsx",
      "parent": "/console/_authenticated/_layout"
    },
    "/console/_authenticated/_layout/coupons/": {
      "filePath": "console/_authenticated/_layout/coupons/index.tsx",
      "parent": "/console/_authenticated/_layout"
    },
    "/console/_authenticated/_layout/devices/": {
      "filePath": "console/_authenticated/_layout/devices/index.tsx",
      "parent": "/console/_authenticated/_layout"
    },
    "/console/_authenticated/_layout/orders/": {
      "filePath": "console/_authenticated/_layout/orders/index.tsx",
      "parent": "/console/_authenticated/_layout"
    },
    "/console/_authenticated/_layout/payments/": {
      "filePath": "console/_authenticated/_layout/payments/index.tsx",
      "parent": "/console/_authenticated/_layout"
    },
    "/console/_authenticated/_layout/products/": {
      "filePath": "console/_authenticated/_layout/products/index.tsx",
      "parent": "/console/_authenticated/_layout"
    },
    "/console/_authenticated/_layout/users/": {
      "filePath": "console/_authenticated/_layout/users/index.tsx",
      "parent": "/console/_authenticated/_layout"
    },
    "/console/_authenticated/_layout/users/$username": {
      "filePath": "console/_authenticated/_layout/users/$username",
      "parent": "/console/_authenticated/_layout",
      "children": [
        "/console/_authenticated/_layout/users/$username/_layout"
      ]
    },
    "/console/_authenticated/_layout/users/$username/_layout": {
      "filePath": "console/_authenticated/_layout/users/$username/_layout.tsx",
      "parent": "/console/_authenticated/_layout/users/$username",
      "children": [
        "/console/_authenticated/_layout/users/$username/_layout/access-keys",
        "/console/_authenticated/_layout/users/$username/_layout/change-password",
        "/console/_authenticated/_layout/users/$username/_layout/"
      ]
    },
    "/console/_authenticated/_layout/settings/_layout/store": {
      "filePath": "console/_authenticated/_layout/settings/_layout/store.lazy.tsx",
      "parent": "/console/_authenticated/_layout/settings/_layout"
    },
    "/console/_authenticated/_layout/settings/_layout/": {
      "filePath": "console/_authenticated/_layout/settings/_layout/index.tsx",
      "parent": "/console/_authenticated/_layout/settings/_layout"
    },
    "/console/_authenticated/_layout/users/$username/_layout/access-keys": {
      "filePath": "console/_authenticated/_layout/users/$username/_layout/access-keys.lazy.tsx",
      "parent": "/console/_authenticated/_layout/users/$username/_layout"
    },
    "/console/_authenticated/_layout/users/$username/_layout/change-password": {
      "filePath": "console/_authenticated/_layout/users/$username/_layout/change-password.lazy.tsx",
      "parent": "/console/_authenticated/_layout/users/$username/_layout"
    },
    "/console/_authenticated/_layout/users/$username/_layout/": {
      "filePath": "console/_authenticated/_layout/users/$username/_layout/index.lazy.tsx",
      "parent": "/console/_authenticated/_layout/users/$username/_layout"
    }
  }
}
ROUTE_MANIFEST_END */
