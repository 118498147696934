import React from 'react';
import { PaymentTable } from '@/components/payment-table';
import { createFileRoute } from '@tanstack/react-router';
import { useQuery } from '@tanstack/react-query';
import { fetchPayments } from '@/services/payment';
import { Form } from '@/components/ui/form';
import { TextField } from '@/components/text-field';
import { DatePicker } from '@/components/date-picker';
import { SelectField } from '@/components/select-field';
import { Button } from '@/components/ui/button';
import { Search } from 'lucide-react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { fetchPaymentMethods } from '@/services/payment-method';
export const Route = createFileRoute('/console/_authenticated/_layout/payments/')({
    component: Payments,
});
function Payments() {
    const { t } = useTranslation();
    const { data: paymentMethods = [] } = useQuery({
        queryKey: ['payment-methods'],
        queryFn: () => fetchPaymentMethods(),
    });
    const form = useForm({
        defaultValues: {
            invoiceNumber: '',
            from: null,
            to: null,
            paymentMethod: '0',
        },
    });
    const { data = [], refetch } = useQuery({
        queryKey: ['payments'],
        queryFn: () => fetchPayments({ ...form.getValues(), paymentMethod: form.getValues('paymentMethod') }),
    });
    const options = React.useMemo(() => paymentMethods.map((paymentMethod) => ({
        label: paymentMethod.name,
        value: paymentMethod.canonicalName,
    })), [paymentMethods]);
    const onSearch = () => {
        refetch().then();
    };
    return (<div className="w-full">
            <div className="py-4 border rounded-lg p-2 mb-2">
                <Form {...form}>
                    <div className="flex flex-col p-2 gap-2">
                        <TextField control={form.control} name="invoiceNumber" label={t('invoice_number')} placeholder={t('placeholder.order.invoice_number', { ns: 'glossary' })}/>
                        <div className="flex flex-row space-x-2 items-end justify-center w-full">
                            <DatePicker control={form.control} name="from" label={t('from')} disabledDate={(date) => date > new Date() || date < new Date('1900-01-01')}/>
                            <DatePicker control={form.control} name="to" label={t('to')} disabledDate={(date) => date > new Date() || date < new Date('1900-01-01')}/>
                            <SelectField control={form.control} name="paymentMethod" options={options} label={t('payment_method')} placeholder={t('placeholder.select.payment_method', { ns: 'glossary' })}/>
                        </div>
                    </div>
                </Form>
                <div className="flex flex-row justify-center items-center space-x-2">
                    <Button variant="outline" className="bg-yellow-500 text-background" onClick={onSearch}>
                        <Search />
                        {t('search')}
                    </Button>
                </div>
            </div>

            <PaymentTable payments={data}/>
        </div>);
}
