import i18next from '@/i18next';
export const DATETIME_FORMAT = 'MMMM dd, yyyy hh:mm';
export const DATE_FORMAT = 'dd/MM/yyyy';
export const DATE_WITH_TIME_FORMAT = 'dd/MM/yyyy hh:mm';
export const DAYS = [
    i18next.t('days.sunday'),
    i18next.t('days.monday'),
    i18next.t('days.tuesday'),
    i18next.t('days.wednesday'),
    i18next.t('days.thursday'),
    i18next.t('days.friday'),
    i18next.t('days.saturday'),
];
export const MONTHS = [
    i18next.t('months.january'),
    i18next.t('months.february'),
    i18next.t('months.march'),
    i18next.t('months.april'),
    i18next.t('months.may'),
    i18next.t('months.june'),
    i18next.t('months.july'),
    i18next.t('months.august'),
    i18next.t('months.september'),
    i18next.t('months.october'),
    i18next.t('months.november'),
    i18next.t('months.december'),
];
