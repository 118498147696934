import React from 'react';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { ChartContainer, ChartLegend, ChartLegendContent, ChartTooltip, ChartTooltipContent, } from '@/components/ui/chart';
import { Bar, BarChart, CartesianGrid, LabelList, XAxis } from 'recharts';
import { useQuery } from '@tanstack/react-query';
import { fetchCategoryShares } from '@/services/category';
import { useTranslation } from 'react-i18next';
import { Separator } from '@/components/ui/separator';
import { PeriodBadge } from '@/components/period-badges';
import { usePeriod } from '@/hooks/use-period';
import { endOfDay, startOfDay } from 'date-fns';
import { ChartPeriodButtons } from '@/components/chart-period-buttons';
import { getBarStackedDataAndConfig } from '@/lib/config';
export const CategoryBarStacked = React.memo(() => {
    const { t } = useTranslation();
    const [state, dispatch] = usePeriod({ period: 'D', from: startOfDay(new Date()), to: endOfDay(new Date()) });
    const { data = [] } = useQuery({
        queryKey: ['category-bar-stacked', state.from, state.to, state.period],
        queryFn: () => fetchCategoryShares({ from: state.from, to: state.to, groupType: state.period }),
    });
    const [categories, chartConfig] = React.useMemo(() => getBarStackedDataAndConfig(data, state.period, state.from || new Date()), [data, state.from, state.period]);
    const totalShares = React.useMemo(() => data.reduce((acc, item) => acc + item.share, 0), [data]);
    const renderChart = React.useCallback(() => {
        if (data.length > 0) {
            return (<ChartContainer config={chartConfig} className="mx-auto aspect-square w-full max-h-[450px]">
                    <BarChart accessibilityLayer data={categories} innerRadius={60}>
                        <CartesianGrid vertical={false}/>
                        <XAxis dataKey="xAxis" tickLine={false} tickMargin={10} axisLine={false} tickFormatter={(value) => value.slice(0, 3)}/>
                        <ChartTooltip content={<ChartTooltipContent hideLabel/>}/>
                        <ChartLegend content={<ChartLegendContent className="flex-wrap"/>}/>
                        {Object.keys(chartConfig).map((category) => (<Bar dataKey={category} fill={chartConfig[category].color} stackId="stack" key={`${category}`}>
                                <LabelList dataKey={category} position="centerBottom" className="fill-foreground font-bold" fontSize={16}/>
                            </Bar>))}
                    </BarChart>
                </ChartContainer>);
        }
        return (<div className="h-[450px] items-center justify-center flex">
                <p className="text-2xl">{t('empty.data', { ns: 'glossary' })}</p>
            </div>);
    }, [categories, chartConfig, data, t]);
    return (<Card className="flex flex-col w-full">
            <CardHeader className="items-center">
                <CardTitle>{t('category.chart.title', { ns: 'glossary' })}</CardTitle>
                <CardDescription>{t('category.chart.description', { ns: 'glossary' })}</CardDescription>
            </CardHeader>
            <Separator />
            <CardContent className="flex-1 pb-0 space-y-2">
                <ChartPeriodButtons state={state} dispatch={dispatch} total={totalShares}/>
                {renderChart()}
            </CardContent>
            <CardFooter className="justify-center items-center w-full pt-6 space-x-2">
                <PeriodBadge value={state.period} dispatch={dispatch}/>
            </CardFooter>
        </Card>);
});
