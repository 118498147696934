import React from 'react';
import { Button } from '@/components/ui/button';
import { ArrowDown, ArrowUp, Eye } from 'lucide-react';
import { getUserFullName } from '@/lib/string';
import { useReactTable } from '@tanstack/react-table';
import { createColumnHelper, getCoreRowModel, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, } from '@tanstack/table-core';
import { Table } from '@/components/table';
import { useTranslation } from 'react-i18next';
import { Link } from '@tanstack/react-router';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';
import { format } from 'date-fns';
import { DATE_FORMAT } from '@/constants/date';
const columnHelper = createColumnHelper();
export const CouponTable = ({ coupons }) => {
    const { t } = useTranslation();
    const [sorting, setSorting] = React.useState([]);
    const [columnFilters, setColumnFilters] = React.useState([]);
    const [columnVisibility, setColumnVisibility] = React.useState({});
    const [rowSelection, setRowSelection] = React.useState({});
    const columns = React.useMemo(() => [
        columnHelper.accessor('code', {
            id: 'view',
            header: () => t('view'),
            cell: ({ getValue }) => (<Button size="icon" variant="ghost" asChild>
                        <Link to={`/console/coupons/${getValue()}`}>
                            <Eye />
                        </Link>
                    </Button>),
            footer: (info) => info.column.id,
            meta: {
                style: {
                    textAlign: 'center',
                },
            },
        }),
        columnHelper.accessor('title', {
            header: ({ column }) => (<Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}>
                        {t('title')}
                        {column.getIsSorted() === 'asc' ? <ArrowUp /> : <ArrowDown />}
                    </Button>),
            cell: (info) => info.renderValue(),
            footer: (info) => info.column.id,
        }),
        columnHelper.accessor('type', {
            header: ({ column }) => (<Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}>
                        {t('coupon_type')}
                        {column.getIsSorted() === 'asc' ? <ArrowUp /> : <ArrowDown />}
                    </Button>),
            cell: (info) => t(`coupon.type.${info.getValue()}`),
            footer: (info) => info.column.id,
        }),
        columnHelper.accessor('discountType', {
            header: ({ column }) => (<Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}>
                        {t('discount_type')}
                        {column.getIsSorted() === 'asc' ? <ArrowUp /> : <ArrowDown />}
                    </Button>),
            cell: (info) => t(`coupon.discount_type.${info.getValue()}`),
            footer: (info) => info.column.id,
        }),
        columnHelper.accessor('startDate', {
            header: ({ column }) => (<Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}>
                        {t('start_date')}
                        {column.getIsSorted() === 'asc' ? <ArrowUp /> : <ArrowDown />}
                    </Button>),
            cell: (info) => format(new Date(info.getValue()), DATE_FORMAT),
            footer: (info) => info.column.id,
        }),
        columnHelper.accessor('endDate', {
            header: ({ column }) => (<Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}>
                        {t('end_date')}
                        {column.getIsSorted() === 'asc' ? <ArrowUp /> : <ArrowDown />}
                    </Button>),
            cell: (info) => format(new Date(info.getValue()), DATE_FORMAT),
            footer: (info) => info.column.id,
        }),
        columnHelper.accessor('discountValue', {
            header: ({ column }) => (<Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}>
                        {t('discount_value')}
                        {column.getIsSorted() === 'asc' ? <ArrowUp /> : <ArrowDown />}
                    </Button>),
            cell: ({ renderValue }) => renderValue(),
            footer: (info) => info.column.id,
            meta: {
                style: { textAlign: 'right' },
            },
        }),
        columnHelper.accessor('usageLimit', {
            header: ({ column }) => (<Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}>
                        {t('usage_limit')}
                        {column.getIsSorted() === 'asc' ? <ArrowUp /> : <ArrowDown />}
                    </Button>),
            cell: ({ renderValue }) => renderValue(),
            footer: (info) => info.column.id,
            enableSorting: false,
            enableColumnFilter: false,
            meta: {
                style: { textAlign: 'right' },
            },
        }),
        columnHelper.accessor('minOrderValue', {
            header: ({ column }) => (<Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}>
                        {t('min_order_value')}
                        {column.getIsSorted() === 'asc' ? <ArrowUp /> : <ArrowDown />}
                    </Button>),
            cell: ({ renderValue }) => renderValue(),
            footer: (info) => info.column.id,
            enableSorting: false,
            enableColumnFilter: false,
            meta: {
                style: { textAlign: 'right' },
            },
        }),
        columnHelper.accessor('user.username', {
            header: ({ column }) => (<Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}>
                        {t('user')}
                        {column.getIsSorted() === 'asc' ? <ArrowUp /> : <ArrowDown />}
                    </Button>),
            cell: (info) => (<Tooltip>
                        <TooltipTrigger asChild>
                            <Button variant="link" asChild>
                                <Link to={`/console/users/${info.row.original.user.usernameCanonical}`}>
                                    {info.renderValue()}
                                </Link>
                            </Button>
                        </TooltipTrigger>
                        <TooltipContent>
                            <p>{getUserFullName(info.row.original.user)}</p>
                        </TooltipContent>
                    </Tooltip>),
            footer: (info) => info.column.id,
        }),
    ], [t]);
    const table = useReactTable({
        data: coupons,
        columns,
        onSortingChange: setSorting,
        onColumnFiltersChange: setColumnFilters,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        onColumnVisibilityChange: setColumnVisibility,
        onRowSelectionChange: setRowSelection,
        state: {
            sorting,
            columnFilters,
            columnVisibility,
            rowSelection,
        },
    });
    return <Table table={table} columns={columns}/>;
};
